import React, { useContext, useEffect, useState } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { useLocation } from "react-router-dom";
import logo from "../../../assets/logo-dark.svg";
import { PrimaryButton } from "../../../components/button/primary";
import { AuthContext } from "../../../contexts/AuthContext"; // Import auth context

function Login() {
  const { login, isAuthenticated, getToken, isLoading } = useKindeAuth();
  const location = useLocation();
  // const { authContext } = useContext(AuthContext); // Access auth context
  // const authOrgId = authContext?.authOrgId || ""; // Keep org ID
  // const [isLoading, setIsLoading] = useState(true); // Track loading state

  const handleClick = async () => {
    console.log("Clicked");
    await login({
      // org_code: authOrgId,
      app_state: {
        redirectTo: location.state ? location.state.from.pathname : "/",
      },
    });
  };

  // Auto-trigger login if user is not authenticated and token is available
  // useEffect(() => {
  //   const autoLogin = async () => {
  //     try {
  //       const token = await getToken();
  //       if (!isAuthenticated && token) {
  //         // Only auto-login if token is available
  //         await login({
  //           // org_code: authOrgId,
  //           app_state: {
  //             redirectTo: location.state ? location.state.from.pathname : "/",
  //           },
  //         });
  //       } else {
  //         // Stop loading if no token is available or already authenticated
  //         setIsLoading(false);
  //       }
  //     } catch (error) {
  //       console.error("Auto-login failed:", error); // Catch errors
  //       setIsLoading(false); // Stop loading if auto-login fails
  //     }
  //   };

  //   autoLogin();
  // }, [isAuthenticated, getToken, login, authOrgId, location.state]);

  // Prevent rendering login button if auto-login is in progress or user is authenticated
  // if (isLoading || isAuthenticated) {
  //   return (
  //     <div className="flex h-screen justify-center items-center bg-black">
  //       <div className="sm:px-12 lg:px-24 sm:py-8 lg:py-16 rounded-lg bg-white flex rounded-large items-center justify-center flex-col">
  //         <img src={logo} alt="Opspot" />
  //         <div className="pt-12">Logging you in...</div>
  //       </div>
  //     </div>
  //   );
  // }

  return (
    <div className="flex h-screen justify-center items-center bg-black">
      <div className="sm:px-12 md:px-18 lg:px-24 sm:py-8 lg:py-16 rounded-lg bg-white flex rounded-large items-center justify-center flex-col">
        <img src={logo} alt="Opspot" />
        <div className="pt-12 w-full">
          <PrimaryButton label="Sign in" onClick={handleClick} />
        </div>
      </div>
    </div>
  );
}

export { Login };
