import React, { useEffect } from "react";
import Lottie from "react-lottie";
import * as animationData from "../../assets/lottie/app-loading.json";

const PageLoadingAnimation = ({ isLoading, navSpacer, nonAnimated }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  if (nonAnimated) {
    defaultOptions.autoplay = false;
  }

  if (!isLoading) return null;

  return (
    <>
      {navSpacer && (
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-5 lg:flex lg:w-80 lg:flex-col">
          <div className="flex grow flex-col overflow-y-auto bg-black"></div>
        </div>
      )}
      <div className="fixed inset-0 bg-black bg-opacity-100 flex justify-center items-center z-50">
        <Lottie options={defaultOptions} height={100} width={100} />
      </div>
    </>
  );
};

export { PageLoadingAnimation };
