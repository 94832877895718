import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { AuthContext } from "../../../contexts/AuthContext";

const AuthCallback = () => {
  const { isAuthenticated, isLoading } = useKindeAuth();
  const { authContext } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoading && isAuthenticated) {
      if (authContext.userId) {
        navigate(authContext.initializationComplete ? "/" : "/organization");
      }
      // Else, wait until authContext is populated
    } else if (!isAuthenticated && !isLoading) {
      navigate("/auth/login");
    }
    // Optionally handle loading state here
  }, [isAuthenticated, isLoading, authContext, navigate]);

  return <div>Loading...</div>;
};

export { AuthCallback };
