import React, { useEffect, useState, useContext } from "react";
// import { useNavigate } from "react-router-dom";
import Navigation from "../../components/navigation";
import { Tabs } from "../../components/tabs";
import { TextInput } from "../../components/textInput";
import { ImageInput } from "../../components/imageInput";
import { Toast } from "../../components/toast";
import { Button } from "../../components/button";
import { useForm, FormProvider } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { AuthContext } from "../../contexts/AuthContext";
import { PageLoadingAnimation } from "../../components/loading/pageLoading";
import logo from "../../assets/logo-mark.svg";
import bgImage from "../../assets/bg/simple-opaque.png";
import { useNavigate } from "react-router-dom";
import { z } from "zod";

const schema = z.object({
  name: z.string().min(1, "Name is required"),
});

function Organization() {
  const { authContext } = useContext(AuthContext);
  const orgId = authContext.orgId;
  const navigate = useNavigate();
  const [isInitializing, setIsInitializing] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [backendData, setBackendData] = useState({
    name: "",
    logo_url: "",
    initialization_complete: 0,
  });
  const [planId, setPlanId] = useState(null);

  const [isPageLoading, setIsPageLoading] = useState(true);

  setTimeout(() => {
    setIsPageLoading(false);
  }, 1200);

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      name: "",
      logo_url: "",
    },
    resolver: zodResolver(schema),
  });

  const { handleSubmit, setValue } = methods;

  /// Toast ///
  const [toast, setToast] = useState({ show: false, message: "", type: "" });
  const handleResponse = (message, type) => {
    setToast({ show: true, message, type });
    if (backendData.initialization_complete === 0) {
      setTimeout(() => {
        navigate("/");
      });
    }
  };

  const hideToast = () => {
    setToast((prev) => ({ ...prev, show: false }));
  };

  const onSubmit = (data) => {
    return updateOrganization(data);
  };

  /// Images ///
  const [imageFile, setImageFile] = useState(null);

  /// Fetch organization details ///
  const fetchOrganization = () => {
    if (!orgId) {
      return;
    }
    fetch(`${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}`)
      .then((response) => {
        if (!response.ok)
          throw new Error(`Fetch returned status ${response.status}`);
        return response.json();
      })
      .then((data) => {
        setBackendData({
          name: data.name,
          logo_url: data.logo_url,
          initialization_complete: data.initialization_complete,
        });
        setValue("name", data.name);
        setIsFetching(false);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  useEffect(() => {
    fetchOrganization();
  }, [orgId]);

  useEffect(() => {
    if (isFetching === false && backendData.initialization_complete === 0) {
      setIsInitializing(true);
    }
  }, [isFetching, backendData]);

  /// Update user ///
  function updateOrganization(data) {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key !== "customers") {
        formData.append(key, data[key]);
      }
    });

    if (imageFile) {
      formData.append("file", imageFile);
    } else if (backendData.logo_url) {
      formData.append("logo", backendData.logo_url);
    }

    const url = isInitializing
      ? `${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}?initializing=true`
      : `${process.env.REACT_APP_API_URL}/v1/organizations/${orgId}`;

    console.log("URL", url);
    console.log("Initializing", isInitializing);

    fetch(url, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        if (!response.ok)
          throw new Error(`Fetch returned status ${response.status}`);
        return response.json();
      })
      .then(() => {
        // onSuccess();
        handleResponse("Organization details updated successfully", "success");
      })
      .catch((error) => {
        console.error("Error:", error);
        handleResponse("Failed to update organization details", "danger");
      });
  }

  const onError = (errors, event) => {
    console.log("form error");
    console.log(errors);
  };

  /// Image upload ///
  function handleImageChange(event) {
    const file = event.target.files[0];
    if (!file) {
      console.log("No file selected.");
      return;
    }

    setImageFile(file);
  }

  /// Get and set the plan id passed via registration ///
  useEffect(() => {
    // Check if we're done initializing and the plan is available
    if (isInitializing) {
      const storedPlanId = localStorage.getItem("selectedPlan");
      if (storedPlanId) {
        console.log("Initializing with plan id:", storedPlanId);
        setPlanId(storedPlanId);
      }
    }
  }, [isInitializing]); // Only run this effect when `isInitializing` changes

  useEffect(() => {
    console.log("Plan id:", planId);
  }, [planId]);

  return !isFetching ? (
    <div
      className={`relative ${
        isInitializing ? "bg-no-repeat h-screen bg-left-bottom" : ""
      }`}
      style={
        isInitializing
          ? {
              backgroundImage: `url(${bgImage})`,
              backgroundSize: "25%", // Adjust size of the image (50% of its original size)
              backgroundPosition: "calc(100% - 10px) calc(100% + 200px)",
            }
          : {}
      }
    >
      {!isInitializing && (
        <Navigation heading="Organization" current="organization" />
      )}

      {/* <Tabs current="Activity" section="reporting" /> */}
      <main className="py-6 lg:pl-80">
        {!isInitializing ? (
          <PageLoadingAnimation isLoading={isPageLoading} />
        ) : (
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex items-center">
              <div className="flex-1 py-6">
                <div className="space-y-6 w-1/3">
                  <div className="mt-16">
                    <img src={logo} alt="Opspot" className="w-12" />
                  </div>
                  <p className="text-sm text-gray-500">
                    Welcome to Opspot! What can we call you?
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center mb-4">
            <FormProvider {...methods}>
              <div className="flex-1 py-6">
                <div className="space-y-12 w-1/3">
                  <TextInput name="name" label="Organization name" />
                  <ImageInput
                    name="logo"
                    label="Logo (optional)"
                    onChange={handleImageChange}
                    accept="image/png, image/jpeg, image/gif, image/svg+xml, image/webp"
                    maxFileSize={3201010}
                    existing={backendData.logo_url}
                  />
                  <Button
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    className="w-full"
                    label="Save"
                  />
                </div>
              </div>
            </FormProvider>

            <Toast
              show={toast.show}
              message={toast.message}
              type={toast.type}
              onHide={hideToast}
            />
          </div>
        </div>
      </main>
    </div>
  ) : null;
}

export { Organization };
