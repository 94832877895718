import React from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

function Profile() {
  const { user, isAuthenticated, isLoading, getPermission } = useKindeAuth();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return isAuthenticated ? (
    <div>
      <h2>Profile</h2>
      <p>First name: {user.given_name}</p>
      <p>Last name: {user.family_name}</p>
      <p>Email: {user.email}</p>
      <p>User Id: {user.id}</p>
      <p>Organization Code: {getPermission().orgCode}</p>
    </div>
  ) : (
    <p>Please sign in or register!</p>
  );
}

export { Profile };
