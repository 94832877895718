import React, { useState, useEffect } from "react";

const ReportingActivityEmergencyCalls = ({ details }) => {
  return (
    <div>
      <div className="flex pb-2">
        <h3 className="w-[50%] text-gray-500 text-sm">Customer name:</h3>
        <p className="text-gray-600 text-sm">{details.customer_name}</p>
      </div>
      <div className="flex pb-2">
        <h3 className="w-[50%] text-gray-500 text-sm">Location name:</h3>
        <p className="text-gray-600 text-sm">{details.location_name}</p>
      </div>
      <div className="flex pb-2">
        <h3 className="w-[50%] text-gray-500 text-sm">Contact type:</h3>
        <p className="text-gray-600 text-sm">
          {details.emergency_contact_type_name}
        </p>
      </div>
      <div className="flex pb-2">
        <h3 className="w-[50%] text-gray-500 text-sm">Contact name:</h3>
        <p className="text-gray-600 text-sm">
          {details.emergency_contact_number}
        </p>
      </div>
    </div>
  );
};

export { ReportingActivityEmergencyCalls };
