import React, {
  createContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const { isAuthenticated, isLoading, getToken, user, getPermission, logout } =
    useKindeAuth();

  const navigate = useNavigate();
  const location = useLocation();
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 5;
  const retryDelay = 1500;

  // Initialize state with either localStorage or empty values
  const [authContext, setAuthContext] = useState(() => {
    const storedAuthContext = localStorage.getItem("authContext");
    return storedAuthContext
      ? JSON.parse(storedAuthContext)
      : {
          userId: "",
          authId: "",
          orgId: "",
          orgName: "",
          authOrgId: "",
          accessLevel: 1,
          firstName: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          imageUrl: "",
          initializationComplete: "",
          role: "",
          plan: "",
        };
  });

  // Save authContext to localStorage on updates
  useEffect(() => {
    if (authContext.userId) {
      localStorage.setItem("authContext", JSON.stringify(authContext));
    }
  }, [authContext]);

  // Fetch user/org data when authenticated and not already loaded
  useEffect(() => {
    const isAuthLoginRoute = location.pathname === "/auth/login";

    if (isAuthLoginRoute) {
      console.log("Skipping /auth/login route...");
      return;
    }

    const fetchUserData = async () => {
      if (!isAuthenticated || isLoading || authContext.userId) return;

      try {
        const token = await getToken();
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/v1/auth/login/${user.id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error(
            `Failed to fetch user data. Status: ${response.status}`
          );
        }

        const data = await response.json();

        // Set auth context with fetched data
        const newAuthContext = {
          userId: data.id,
          authId: user.id,
          orgId: data.org_id,
          orgName: data.org_name,
          authOrgId: getPermission().orgCode,
          accessLevel: "1",
          firstName: data.first_name,
          lastName: data.last_name,
          email: data.email,
          phoneNumber: data.phone_number,
          imageUrl: data.profile_url,
          initializationComplete: data.initialization_complete,
          role: data.role,
          plan: data.plan,
        };

        setAuthContext(newAuthContext);
        console.log("User data fetched:", newAuthContext);
        localStorage.setItem("authContext", JSON.stringify(newAuthContext));

        // Redirect based on initialization status
        if (newAuthContext.initializationComplete) {
          navigate("/");
        } else {
          navigate("/organization");
        }
      } catch (error) {
        console.error("Error during the auth callback process:", error);

        // Retry logic
        if (retryCount < maxRetries) {
          console.log(`Retrying in ${retryDelay * (retryCount + 1)} ms...`);
          setTimeout(() => {
            setRetryCount((prev) => prev + 1);
          }, retryDelay * (retryCount + 1)); // Incremental backoff
        } else {
          console.error("Max retries reached. Could not complete setup.");
          // Optionally handle max retries reached (e.g., redirect to an error page)
        }
      }
    };

    fetchUserData();
  }, [
    isAuthenticated,
    isLoading,
    user,
    getToken,
    getPermission,
    authContext.userId,
    location.pathname,
    navigate,
    retryCount,
    maxRetries,
    retryDelay,
  ]);

  // Memoize the authContext to prevent unnecessary re-renders
  const memoizedAuthContext = useMemo(() => authContext, [authContext]);

  // Memoized handleLogout function to reset authContext and logout the user
  const handleLogout = useCallback(async () => {
    try {
      localStorage.removeItem("authContext");
      setAuthContext({
        userId: "",
        authId: "",
        orgId: "",
        orgName: "",
        authOrgId: "",
        accessLevel: 1,
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        imageUrl: "",
        initializationComplete: "",
        role: "",
        plan: "",
      });
      await logout();
      navigate("/auth/login");
    } catch (error) {
      console.error("Logout failed:", error);
    }
  }, [logout, navigate]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        isLoading,
        getToken,
        authContext: memoizedAuthContext,
        setAuthContext,
        logout: handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
