import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { PrintModal } from "../../../../components/modal/printModal";
import { TrashIcon } from "@heroicons/react/24/outline";
import { Table } from "../../../../components/table";
import emptyCheckpoint from "../../../../assets/icons/empty-checkpoint.svg";
import { LinkButton } from "../../../../components/button/link";

function Checkpoints({
  handleCheckpointsAddMode,
  fetchCheckpointsList,
  checkpointsListData,
  handleDeleteCheckpoint,
  setIsCheckpointsAddMode,
  toggleOpen,
}) {
  const [isPrintModalOpen, setPrintModalOpen] = useState(false);
  const [selectedCheckpoint, setSelectedCheckpoint] = useState(null);

  useEffect(() => {
    fetchCheckpointsList();
  }, []);

  const handlePrintModalOpen = (checkpoint) => {
    setSelectedCheckpoint(checkpoint);
    setPrintModalOpen(true);
  };

  const handlePrintModalClose = () => {
    setPrintModalOpen(false);
    setSelectedCheckpoint(null);
  };

  useEffect(() => {
    console.log("checkpointsListData", checkpointsListData);
  }, [checkpointsListData]);

  /// Table columns ///
  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "checkpoint_name",
        // Use a custom cell to render the link
        Cell: ({ row }) => (
          <div>
            <a
              onClick={() => {
                handleCheckpointsAddMode(row.original.id, row.original.type_id);
              }}
              className="text-gray-500 hover:text-gray-600 hover:underline cursor-pointer"
            >
              {row.values.checkpoint_name}
            </a>
          </div>
        ),
      },
      {
        Header: "Description",
        accessor: "checkpoint_description",
      },
      {
        Header: "Type",
        accessor: "checkpoint_type",
        Cell: ({ row }) => (
          <div>
            {row.original.type_id === 2 ? (
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  handlePrintModalOpen(row.original);
                }}
                className="text-blue-500 hover:text-blue-700 hover:underline"
              >
                {row.original.type_name}
              </a>
            ) : (
              row.original.type_name
            )}
          </div>
        ),
      },
      {
        Header: "",
        id: "delete",
        accessor: (str) => "delete",
        Cell: ({ row }) => (
          <button
            onClick={() =>
              handleDeleteCheckpoint(
                row.original.checkpoint_name,
                row.original.id
              )
            }
            className="text-gray-600 hover:text-gray-500"
          >
            <TrashIcon className="h-4 w-4 shrink-0" />
          </button>
        ),
      },
    ],
    []
  );

  return (
    <div>
      {typeof checkpointsListData === "undefined" ? (
        <p>Loading...</p>
      ) : checkpointsListData.length > 0 ? (
        <Table data={checkpointsListData} columns={columns} />
      ) : (
        <div className="flex flex-col items-center justify-center h-96">
          <img src={emptyCheckpoint} className="text-black w-36 h-36" />
          <p className="mt-0 text-gray-300 text-center text-md ">
            No checkpoints yet
          </p>
          <LinkButton
            type="function"
            text="Create checkpoint"
            onClick={() => {
              setIsCheckpointsAddMode(true);
              toggleOpen();
            }}
          />
        </div>
      )}

      {isPrintModalOpen && (
        <PrintModal
          isOpen={isPrintModalOpen}
          toggleOpen={handlePrintModalClose}
          selectedItem={selectedCheckpoint}
          title={`Print QR code for ${selectedCheckpoint?.checkpoint_name}?`}
          message={`Print this QR code and place in the desired location for your team to scan and conduct the checkpoint.`}
          handleSuccess={() => {
            // handle success logic here
          }}
          onPrint={() => {
            // handle print logic here
          }}
        />
      )}
    </div>
  );
}

export { Checkpoints };
