import React from "react";
import { Routes, Route } from "react-router-dom";

// Importing components
import { Dashboard } from "../containers/dashboard";
import { Login } from "../containers/auth/login";
import { Logout } from "../containers/auth/logout";
import { Register } from "../containers/auth/register";
import { AuthCallback } from "../containers/auth/callback";
import { Profile } from "../containers/profile/index";
import { Customers } from "../containers/settings/customers";
import { Users } from "../containers/settings/users";
import { LocationSettings } from "../containers/settings/locations";
import { LocationSettingsDetails } from "../containers/settings/locations/details";
import { Reporting } from "../containers/reporting";
import { Scheduling } from "../containers/scheduling";
import { Organization } from "../containers/organization";
import ProtectedRoute from "./ProtectedRoute";

const RoutesWrapper = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/auth/login/" element={<Login />} />
      <Route path="/auth/logout/" element={<Logout />} />
      <Route path="/auth/callback/" element={<AuthCallback />} />
      <Route path="/auth/register/" element={<Register />} />

      {/* Protected Routes */}
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile/"
        element={
          <ProtectedRoute>
            <Profile />
          </ProtectedRoute>
        }
      />
      <Route
        path="/scheduling/"
        element={
          <ProtectedRoute>
            <Scheduling />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reporting/"
        element={
          <ProtectedRoute>
            <Reporting />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/customers/"
        element={
          <ProtectedRoute>
            <Customers />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/locations/"
        element={
          <ProtectedRoute>
            <LocationSettings />
          </ProtectedRoute>
        }
      />
      <Route
        path="/organization/"
        element={
          <ProtectedRoute>
            <Organization />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/locations/:id"
        element={
          <ProtectedRoute>
            <LocationSettingsDetails />
          </ProtectedRoute>
        }
      />
      <Route
        path="/settings/users/"
        element={
          <ProtectedRoute>
            <Users />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export { RoutesWrapper };
