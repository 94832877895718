import React, { useContext } from "react";
import { useKindeAuth } from "@kinde-oss/kinde-auth-react";
import { AuthContext } from "../../../contexts/AuthContext";
import logo from "../../../assets/logo-dark.svg";
import { PrimaryButton } from "../../../components/button/primary";

function Logout() {
  const { login } = useKindeAuth();
  const { authContext } = useContext(AuthContext); // Access auth context
  const authOrgId = authContext?.authOrgId || ""; // Keep org ID for login

  return (
    <div className="flex h-screen justify-center items-center bg-black">
      <div className="sm:px-12 lg:px-24 sm:py-8 lg:py-16 rounded-lg bg-white flex rounded-large items-center justify-center flex-col">
        <img src={logo} alt="Opspot" />
        <div className="leading-relaxed pt-12 w-full text-center">
          You have been logged out.
        </div>
        <div className="pt-10">
          <PrimaryButton
            label="Log back in"
            onClick={() =>
              login({
                org_code: authOrgId, // Use stored org code for login
                app_state: {
                  redirectTo: "/", // Redirect back to home after login
                },
              })
            }
          />
        </div>
      </div>
    </div>
  );
}

export { Logout };
