import React, { forwardRef } from "react";
import { useFormContext } from "react-hook-form";

const TextInput = forwardRef(({ name, label, ...inputProps }, ref) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  return (
    <div className="sm:col-span-3">
      {label && (
        <label
          htmlFor={name}
          className="block text-sm font-medium leading-6 text-gray-600"
        >
          {label}
        </label>
      )}
      <div className="mt-2">
        <input
          {...register(name)}
          {...inputProps}
          id={name}
          type="text"
          autoComplete="off"
          className="block w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-brand-primary sm:text-sm sm:leading-6"
        />
        {errors[name] && (
          <p className="mt-2 text-sm text-red-500">{errors[name]?.message}</p>
        )}
      </div>
    </div>
  );
});

export { TextInput };
