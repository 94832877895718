import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../contexts/AuthContext";
import { PageLoadingAnimation } from "../components/loading/pageLoading";

const ProtectedRoute = ({ children }) => {
  const { isAuthenticated, isLoading } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Wait for the loading state to finish, then check if the user is authenticated
    if (!isLoading && !isAuthenticated) {
      navigate("/auth/login"); // Redirect to login page if not authenticated
    }
  }, [isLoading, isAuthenticated, navigate]);

  // While the auth state is loading, show a loading screen or spinner
  if (isLoading) {
    return (
      <PageLoadingAnimation
        isLoading={isLoading}
        navSpacer={true}
        nonAnimated={true}
      />
    );
    // return <div>Loader...</div>;
  }

  // If authenticated, render the children components (the actual protected content)
  return children;
};

export default ProtectedRoute;
