import React, { useEffect, useState, useContext } from "react";
import Navigation from "../../../components/navigation";
import { Tabs } from "../../../components/tabs";
import { DeleteConfirm } from "../../../components/modal/deleteConfirm";
import { TrashIcon } from "@heroicons/react/24/outline";
import { LinkButton } from "../../../components/button/link";
import { PrimaryButton } from "../../../components/button/primary";
import { AuthContext } from "../../../contexts/AuthContext";
import { LocationsCustomerFilter } from "./customerFilter";
import { LocationDetailsMeta } from "./locationDetailsMeta";
import { Table } from "../../../components/table";
import { PageLoadingAnimation } from "../../../components/loading/pageLoading";
import emptyLocation from "../../../assets/icons/empty-location.svg";

function LocationSettings() {
  const { authContext } = useContext(AuthContext);
  const orgId = authContext.orgId;
  const [initialLoadCompleted, setInitialLoadCompleted] = useState(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedDeleteItem, setSelectedDeleteItem] = useState({
    identifier: "",
    id: "",
  });
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [selectedLocationId, setSelectedLocationId] = useState(null);

  /// Customers dropdown ///
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  const handleSelectedCustomers = (optionId) => {
    setSelectedCustomers((currentSelected) => {
      if (currentSelected.includes(optionId)) {
        return currentSelected.filter((item) => item !== optionId);
      } else {
        return [...currentSelected, optionId];
      }
    });
  };

  const clearSelectedCustomers = () => {
    setSelectedCustomers([]);
  };

  const toggleState = (setState, state) => {
    setState(!state);
  };

  /// Delete modal ///
  const handleDeleteClick = (identifier, id) => {
    setSelectedDeleteItem({ identifier, id });
    toggleState(setIsDeleteOpen, isDeleteOpen);
  };

  const deleteUser = (locationId) => {
    return fetch(
      `${process.env.REACT_APP_API_URL}/web/settings/locations/delete/${locationId}`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
      }
    ).then((response) => response.json());
  };

  const [deleteSuccess, setDeleteSuccess] = useState({
    displaying: false,
    message: "Location has been deleted",
  });

  const handleDeleteSuccess = () => {
    fetchLocations();

    setDeleteSuccess({
      displaying: true,
      message: "Location has been deleted",
    });
    setTimeout(() => {
      setDeleteSuccess({ displaying: false, message: deleteSuccess.message });
    }, 3000);
  };

  const [fetchFlag, setFetchFlag] = useState(false);

  const handleSuccess = () => {
    setFetchFlag(!fetchFlag);
  };

  useEffect(() => {
    fetchLocations();
  }, [fetchFlag]);

  /// Fetch locations ///
  const [backendData, setBackendData] = useState([{}]);
  const fetchLocations = async () => {
    // Early return if customerId is not available
    if (!orgId) return;

    if (initialLoadCompleted) {
      setInitialLoadCompleted(true);
    }

    // Assume selectedIds is an array containing the selected IDs from the dropdown
    const selectedIds = selectedCustomers;

    // Construct the URL with or without the ids parameter
    let url = `${process.env.REACT_APP_API_URL}/v1/locations?org_id=${orgId}&customer_ids=${selectedIds}&include_details=true`;
    console.log("URL", url);
    if (selectedIds.length > 0) {
      const idsParam = selectedIds.join(",");
      url += `&ids=${idsParam}`;
    }

    const fetchPromise = fetch(url)
      .then(async (response) => {
        if (response.ok) {
          const data = await response.json();
          setBackendData(data); // Update state with the fetched data
        } else {
          // Handle HTTP errors e.g., 404, 500 etc.
          console.error("HTTP Error: ", response.statusText);
          // Optionally, update state to display an error message
        }
      })
      .catch((error) => {
        // Handle network errors, parsing errors, etc.
        console.error("Fetching error: ", error.message);
        // Optionally, update state to display an error message
      });

    const timerPromise = new Promise((resolve) => setTimeout(resolve, 1200));

    Promise.all([fetchPromise, timerPromise]).then(() => {
      setInitialLoadCompleted(true); // Set initial load completed after the first fetch
    });
  };

  useEffect(() => {
    fetchLocations();
  }, [orgId, selectedCustomers]);

  /// Table columns ///
  const columns = React.useMemo(
    () => [
      {
        Header: "Location name",
        accessor: "location_name",
        // Use a custom cell to render the link
        Cell: ({ row }) => (
          <a
            href={`/settings/locations/${row.original.id}`}
            className="text-gray-500 hover:text-gray-600 hover:underline capitalize cursor-pointer"
          >
            {row.values.location_name}
          </a>
        ),
      },
      {
        Header: "Address",
        accessor: "street",
      },
      {
        Header: "Customer",
        accessor: "customer_name",
      },
      {
        Header: "",
        id: "delete",
        accessor: (str) => "delete",
        Cell: ({ row }) => (
          <button
            className="pl-6 py-4 text-gray-600 hover:text-gray-500 hover:underline block"
            onClick={() =>
              handleDeleteClick(row.original.location_name, row.original.id)
            }
          >
            <TrashIcon className="h-4 w-4 shrink-0" />
          </button>
        ),
      },
    ],
    []
  );

  return (
    <div>
      <Navigation heading="Settings" current="settings" />
      <Tabs current="Locations" section="settings" />

      <main className="py-6 lg:pl-80">
        <PageLoadingAnimation isLoading={!initialLoadCompleted} />
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between mb-4">
            <LocationsCustomerFilter
              selected={selectedCustomers}
              onUpdate={handleSelectedCustomers}
              onClear={clearSelectedCustomers}
            />

            {
              <div className="justify-end">
                <PrimaryButton
                  label="Create location"
                  onClick={() => {
                    toggleState(setIsDetailsOpen, isDetailsOpen);
                  }}
                />
              </div>
            }
          </div>
          {typeof backendData === "undefined" ? (
            <p>Loading...</p>
          ) : // Then check if backendData is not empty
          backendData.length > 0 ? (
            <Table data={backendData} columns={columns} />
          ) : (
            // If backendData is empty
            <div className="flex flex-col items-center justify-center h-96">
              <img src={emptyLocation} className="text-black w-36 h-36" />
              <p className="mt-0 text-gray-300 text-center text-md ">
                No locations yet
              </p>
              <LinkButton
                type="function"
                text="Create location"
                onClick={() => {
                  toggleState(setIsDetailsOpen, isDetailsOpen);
                }}
              />
            </div>
          )}
        </div>
      </main>
      <DeleteConfirm
        isOpen={isDeleteOpen}
        toggleOpen={() => toggleState(setIsDeleteOpen, isDeleteOpen)}
        selectedDeleteItem={selectedDeleteItem}
        handleDeleteSuccess={handleDeleteSuccess}
        onDelete={deleteUser}
      />
      <LocationDetailsMeta
        isOpen={isDetailsOpen}
        toggleOpen={() => toggleState(setIsDetailsOpen, isDetailsOpen)}
        selectedLocationId={selectedLocationId}
        isAddMode={true}
        locationData={backendData}
        updateLocationData={fetchLocations}
        onSuccess={handleSuccess}
      />
    </div>
  );
}

export { LocationSettings };
