import React, { useEffect, useRef, useState } from "react";

function ImageInput({
  name,
  label,
  onChange,
  accept,
  maxFileSize,
  existing,
  onClear,
}) {
  const [error, setError] = useState(null);
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null); // Create a ref for the file input

  console.log("existing:", existing);

  function handleFileChange(event) {
    setError(null);

    if (event.target && event.target.files && event.target.files.length > 0) {
      const newFile = event.target.files[0];

      if (maxFileSize && newFile.size > maxFileSize) {
        setError({
          message: `File size cannot exceed ${(
            maxFileSize /
            1024 /
            1024
          ).toFixed(2)} MB`,
        });
        event.target.value = "";
        return; // Return early to prevent further processing
      }

      if (!accept.includes(newFile.type)) {
        const acceptedTypes = accept
          .split(", ")
          .map((type) => type.split("/")[1])
          .join(", ");
        setError({
          message: `File type must be one of the following: ${acceptedTypes}`,
        });
        event.target.value = "";
        return; // Return early if the file type is not accepted
      }

      setFile(newFile); // Update the file state
      onChange(event); // Pass the event to the onChange handler
    }
  }

  function handleClear() {
    setFile(null); // Clear the file state
    setError(null); // Clear any errors
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the file input value
    }
    onChange({ target: { files: [] } }); // Trigger the onChange handler with an empty file list
  }

  useEffect(() => {
    if (onClear === true) {
      handleClear();
    }
  }, [onClear]);

  return (
    <>
      <div>
        <div>
          {label && (
            <label
              htmlFor={name}
              className="block text-sm font-medium leading-6 text-gray-600"
            >
              {label}
            </label>
          )}
          <div className="pt-2 flex items-center">
            <div className="m-0 p-0">
              {file ? (
                <img
                  src={URL.createObjectURL(file)}
                  alt="Preview"
                  className="mr-3 h-10 w-10 rounded-full object-cover bg-gray-200 inline-block"
                />
              ) : existing ? (
                <img
                  src={existing}
                  alt="Preview"
                  className="mr-3 h-10 w-10 rounded-full object-cover bg-gray-200 inline-block"
                />
              ) : (
                <span className="mr-3 w-10 h-10 rounded-full bg-gray-200 inline-block"></span>
              )}
            </div>
            <div>
              <input
                type="file"
                name={name}
                id={name}
                ref={fileInputRef} // Attach the ref to the file input
                onChange={handleFileChange}
                accept={accept}
                className="py-1 focus:ring-2 focus:ring-inset focus:ring-brand-primary sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div className="mt">
            {error && (
              <p className=" text-sm text-red-600" id="email-error">
                {error.message}
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export { ImageInput };
